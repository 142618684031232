/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/how-to/querying-data/use-static-query/
 */

import React, { useEffect, useState } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { I18nextProvider } from "react-i18next"
import axios from "axios"
import { Slide, toast, ToastContainer } from "react-toastify"

import CardImage from "../../images/card.png"
import FooterLogo from "../../images/footer_TCC.svg"
import PhoneIcon from "../../images/footer-phone.png"
import LocationIcon from "../../images/footer-location.png"
import EmailIcon from "../../images/footer-email.png"
import WhatsappIcon from "../../images/whatsapp.png"
import GermaniaImage from "../../images/Germania-Mint-Small-Logo.png"
import MintImage from "../../images/Mint-21-Logo-Small.svg"
import FacebookIcon from "../../images/facebook.png"
import InstagramIcon from "../../images/instagram.png"
import TwitterIcon from "../../images/twitter.png"
import FooterPImage from "../../images/foot-p.png"

import Header from "./header"
import "../styles/layout.css"
import Navbar from "./navbar"
import i18n from "../language/i18n"

import "bootstrap/dist/css/bootstrap.min.css"
import "react-toastify/dist/ReactToastify.css"
import { AuthContextProvider } from "../store/authContext"
import { CurrencyContextProvider } from "../store/currencyContext"
import { CountryContextProvider } from "../store/countryContext"
import { CartContextProvider } from "../store/cartContext"
import { ProductContextProvider } from "../store/productContext"
import { Location } from "@reach/router"

const Layout = ({ children }) => {
  const [contactUser, setContactUser] = useState({
    name: "",
    email: "",
    message: "",
  })

  const getPageDetails = async () => {
    // Define the GraphQL query
    const query = `query ThemeSettings {
        
        themeSettings {
          themeSettingsData {
            socialLinks {
              link {
                title
                url
                target
              }
            }
            contactInfo {
              address
              contactNo {
                target
                title
                url
              }
              email {
                target
                title
                url
              }
            }
            formHeading
            linkHeading
            footerLogo {
              node {
                altText
                sourceUrl
              }
            }
            paymentLogo {
              node {
                altText
                sourceUrl
              }
            }
            socialHeading
            contactHeading
            distributorsHeading
            distributorsImage {
              logos {
                node {
                  altText
                  sourceUrl
                }
              }
            }
          }
        }
        generalSettings {
          selectedLanguage {
            code
            name
            image
          }
          defaultLanguage
          enabledCurrencies {
            code
            rate
            symbol
          }
          defaultCurrency
          siteLogo
          siteIcon
          title
          description
          metalsData {
            gold_day_change
            palladium
            palladium_day_change
            platinum
            platinum_day_change
            silver
            silver_day_change
            gold
          }
        }
        menu(id: "17", idType: DATABASE_ID) {
          menuItems {
            nodes {
              label
              uri
            }
          }
        }
      }`

    try {
      const response = await axios.post(
        `${process.env.WP_API_URL}`,
        {
          query: query,
          // variables: variables,
        },
        {
          headers: {
            "Content-Type": "application/json",
            // Authorization: `Bearer YOUR_AUTH_TOKEN`, // If authentication is needed
          },
        }
      )

      // Handle the response
      if (response.status === 200) {
        if (!response?.data?.data?.generalSettings?.defaultCurrency) {
          toast.error("Home Page content not found", {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          })
        } else {
          setThemeHeader(response?.data?.data?.generalSettings)
          //setThemeFooter(response?.data?.data?.themeSettings?.themeSettingsData)
          //setmenuFooter(response?.data?.data?.menu)
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error)
    }
  }

  useEffect(() => {
    setInterval(() => {
      getPageDetails()
    }, [60000])
  }, [])

  const data = useStaticQuery(graphql`
    {
      wpgraphql {
        themeSettings {
          themeSettingsData {
            socialLinks {
              link {
                title
                url
                target
              }
            }
            contactInfo {
              address
              contactNo {
                target
                title
                url
              }
              email {
                target
                title
                url
              }
            }
            formHeading
            linkHeading
            footerLogo {
              node {
                altText
                sourceUrl
              }
            }
            paymentLogo {
              node {
                altText
                sourceUrl
              }
            }
            socialHeading
            contactHeading
            distributorsHeading
            distributorsImage {
              logos {
                node {
                  altText
                  sourceUrl
                }
              }
            }
          }
        }
        generalSettings {
          selectedLanguage {
            code
            name
            image
          }
          defaultLanguage
          enabledCurrencies {
            code
            rate
            symbol
          }
          defaultCurrency
          siteLogo
          siteIcon
          title
          description
          metalsData {
            gold_day_change
            palladium
            palladium_day_change
            platinum
            platinum_day_change
            silver
            silver_day_change
            gold
          }
        }
        menu(id: "17", idType: DATABASE_ID) {
          menuItems {
            nodes {
              label
              uri
            }
          }
        }
      }
    }
  `)

  const [themeHeader, setThemeHeader] = useState(
    data?.wpgraphql?.generalSettings
  )
  const [themeFooter, setThemeFooter] = useState(
    data?.wpgraphql?.themeSettings?.themeSettingsData
  )
  const [menuFooter, setmenuFooter] = useState(data?.wpgraphql?.menu)

  useEffect(() => {
    if (
      !data?.wpgraphql?.generalSettings ||
      !data?.wpgraphql?.themeSettings?.themeSettingsData ||
      !data?.wpgraphql?.menu
    ) {
      toast.error("Unable to fetch Header & Footer settings", {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      })
    }
    /*
    const getThemeDetails = async () => {
      const query = `
        query ThemeSettings {
          themeSettings {
            themeSettingsData {
              socialLinks {
                link {
                  title
                  url
                  target
                }
              }
              contactInfo {
                address
                contactNo {
                  target
                  title
                  url
                }
                email {
                  target
                  title
                  url
                }
              }
              formHeading
              linkHeading
              footerLogo {
                node {
                  altText
                  sourceUrl
                }
              }
              paymentLogo {
                node {
                  altText
                  sourceUrl
                }
              }
              socialHeading
              contactHeading
              distributorsHeading
              distributorsImage {
                logos {
                  node {
                    altText
                    sourceUrl
                  }
                }
              }
            }
          }
          generalSettings {
            selectedLanguage {
              code
              name
              image
            }
              defaultLanguage
              enabledCurrencies {
                code
                rate
                symbol
              }
              defaultCurrency
              siteLogo
              siteIcon
              title
              description
              metalsData {
                gold_day_change
                palladium
                palladium_day_change
                platinum
                platinum_day_change
                silver
                silver_day_change
                gold
              }
            }
          }
        `
      try {
        const response = await axios.post(
          `${process.env.WP_API_URL}`,
          {
            query: query,
            // variables: variables,
          },
          {
            headers: {
              "Content-Type": "application/json",
              // Authorization: `Bearer YOUR_AUTH_TOKEN`, // If authentication is needed
            },
          }
        )

        // Handle the response
        if (response.status === 200) {
          if (
            !response?.data?.data?.generalSettings ||
            !response?.data?.data?.themeSettings?.themeSettingsData
          ) {
            toast.error("Unable to fetch theme settings", {
              position: "bottom-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            })
            return
          }

          setThemeHeader(response?.data?.data?.generalSettings)
          setThemeFooter(response?.data?.data?.themeSettings?.themeSettingsData)
        }
      } catch (error) {
        console.error("Error fetching data:", error)
      }
    }

    getThemeDetails()
    */
  }, [
    data?.wpgraphql?.generalSettings,
    data?.wpgraphql?.themeSettings?.themeSettingsData,
    data?.wpgraphql?.menu,
  ])

  //Prepare user credentials from input
  const handleContact = _e => {
    setContactUser(_prevState => ({
      ..._prevState,
      [_e.target.name]: _e.target.value,
    }))
  }

  const handleSubmit = async () => {
    if (!contactUser.name || !contactUser.email || !contactUser.message) {
      toast.error("Name, Email & message can't be empty!", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      })
      return
    }

    // Get the variables from user input
    const variables = {
      name: contactUser.name,
      email: contactUser.email,
      message: contactUser.message,
    }

    // Define the GraphQL query
    const query = `
      mutation FooterContacForm ($name: String!, $email: String!, $message: String!) {
        footerContactForm(input: {name: $name, email: $email, message: $message}) {
          message
          success
        }
      }
    `

    try {
      const response = await axios.post(
        `${process.env.WP_API_URL}`,
        {
          query: query,
          variables: variables,
        },
        {
          headers: {
            "Content-Type": "application/json",
            // Authorization: `Bearer YOUR_AUTH_TOKEN`, // If authentication is needed
          },
        }
      )

      // Handle the response
      if (response.status === 200) {
        if (!response?.data?.data?.footerContactForm?.success) {
          toast.error("Unable to submit your form", {
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          })
          return
        }

        toast.success("Form submitted successfully", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        })

        setContactUser(() => ({
          name: "",
          email: "",
          message: "",
        }))
      }
    } catch (error) {
      console.error("Error fetching data:", error)
    }
  }

  return (
    <>
      <Location>
        {({ location }) => (
          <AuthContextProvider>
            <ProductContextProvider>
              <CartContextProvider>
                <CountryContextProvider>
                  <CurrencyContextProvider>
                    <I18nextProvider i18n={i18n}>
                      <Header themeHeader={data?.wpgraphql?.generalSettings} />
                      <Navbar metalsData={themeHeader?.metalsData} />
                      <div className="site_wrapper">
                        <main>{children}</main>
                        <footer className="footer">
                          <div className="container">
                            <div className="row">
                              <div className="col-lg-4 footer_part">
                                <div className="footer_heading">
                                  <h3>{themeFooter?.formHeading}</h3>
                                </div>
                                <div className="footer_form">
                                  <div className="textwidget">
                                    <>
                                      <div className="col-lg-12 form-group">
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Your Name"
                                          name="name"
                                          value={contactUser.name}
                                          onChange={handleContact}
                                        />
                                      </div>
                                      <div className="col-lg-12 form-group">
                                        <input
                                          type="email"
                                          className="form-control"
                                          placeholder="Your Email"
                                          name="email"
                                          value={contactUser.email}
                                          onChange={handleContact}
                                        />
                                      </div>
                                      <div className="col-lg-12 form-group">
                                        <textarea
                                          className="form-control"
                                          placeholder="Your Message"
                                          name="message"
                                          value={contactUser.message}
                                          onChange={handleContact}
                                        ></textarea>
                                      </div>
                                      <div className="col-lg-12 form-group">
                                        <button
                                          type="submit"
                                          class="btn"
                                          onClick={handleSubmit}
                                        ></button>
                                      </div>
                                    </>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-3 footer_part">
                                <div className="footer_heading">
                                  <h3>{themeFooter?.linkHeading}</h3>
                                </div>
                                {menuFooter?.menuItems?.nodes?.length > 0 && (
                                  <ul>
                                    {menuFooter?.menuItems?.nodes?.map(item => (
                                      <li key={item?.uri}>
                                        <Link to={item?.uri}>
                                          {item?.label}
                                        </Link>
                                      </li>
                                    ))}
                                  </ul>
                                )}
                              </div>
                              <div className="col-lg-3 footer_part">
                                <div className="footer_heading">
                                  <h3>{themeFooter?.contactHeading}</h3>
                                </div>
                                <div className="footer_address">
                                  <div className="footer_address_icon">
                                    <img src={LocationIcon} />
                                  </div>
                                  <div className="footer_address_content">
                                    <span>
                                      {themeFooter?.contactInfo[0]?.address}
                                    </span>
                                  </div>
                                </div>
                                <div className="footer_address">
                                  <div className="footer_address_icon">
                                    <img src={PhoneIcon} />
                                  </div>
                                  <div className="footer_address_content">
                                    <a
                                      href={
                                        themeFooter?.contactInfo[0]?.contactNo
                                          ?.url
                                      }
                                    >
                                      {
                                        themeFooter?.contactInfo[0]?.contactNo
                                          ?.title
                                      }
                                    </a>
                                  </div>
                                </div>
                                <div className="footer_address">
                                  <div className="footer_address_icon">
                                    <img src={EmailIcon} />
                                  </div>
                                  <div className="footer_address_content">
                                    <a
                                      href={
                                        themeFooter?.contactInfo[0]?.email?.url
                                      }
                                    >
                                      {
                                        themeFooter?.contactInfo[0]?.email
                                          ?.title
                                      }
                                    </a>
                                  </div>
                                </div>
                                <div className="footer_social">
                                  <h4>{themeFooter?.socialHeading}</h4>
                                  <ul>
                                    {themeFooter?.socialLinks.map(
                                      (item, index) => (
                                        <li key={index}>
                                          <a
                                            href={item?.link?.url}
                                            target="_blank"
                                          >
                                            <img
                                              src={
                                                item?.link?.title.match(
                                                  /facebook/
                                                )
                                                  ? FacebookIcon
                                                  : InstagramIcon
                                              }
                                              alt={"footer_social"}
                                            />
                                          </a>
                                        </li>
                                      )
                                    )}
                                  </ul>
                                </div>
                                <div class="payment_card">
                                  <img
                                    src={
                                      themeFooter?.paymentLogo?.node?.sourceUrl
                                    }
                                    alt={"payment_card"}
                                  />
                                </div>
                              </div>
                              <div className="col-lg-2 footer_part footer_tcc">
                                <img
                                  src={themeFooter?.footerLogo?.node?.sourceUrl}
                                  alt={"footer_logo"}
                                />
                                <h3> {themeFooter?.distributorsHeading} </h3>
                                {/* <img src={FooterPImage} /> */}
                                <div className="footer_tcc_gap_image_wrap">
                                  {themeFooter?.distributorsImage.map(
                                    (item, index) => (
                                      <img
                                        src={item?.logos?.node?.sourceUrl}
                                        key={index}
                                        className="footer_tcc_gap_image"
                                        alt={"distributors_logo"}
                                      />
                                    )
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </footer>
                        <div className="whatsapp_logo">
                          <a href="#">
                            <img src={WhatsappIcon} alt={"whatsapp_logo"} />
                          </a>
                        </div>
                      </div>
                    </I18nextProvider>
                  </CurrencyContextProvider>
                </CountryContextProvider>
              </CartContextProvider>
            </ProductContextProvider>
          </AuthContextProvider>
        )}
      </Location>
    </>
  )
}

export default Layout
