import axios from "axios"

export const formatNumberWithCommas = number => {
  return number.toLocaleString("en-US")
}

// export const formatPrice = (price, currency) => {
//   const updatedPrcie = (
//     parseFloat(price.toString().replace(/[^0-9.-]+/g, "")) * currency?.rate
//   ).toFixed(2)
//   return currency?.symbol + updatedPrcie
// }

export const formatPrice = (price, currency) => {
  const numPrice1 = parseFloat(
    price.toString().includes(currency?.symbol ?? "$")
      ? price.replace(currency?.symbol ?? "$", "")
      : +price
  )
  const updatedPrcie = parseFloat(numPrice1).toFixed(2)
  return currency?.symbol + updatedPrcie
}

export const formatPriceForNonAuth = (price, currency) => {
  const numPrice1 = parseFloat(
    price.toString().includes("$") ? price.replace("$", "") : +price
  )
  const updatedPrcie = parseFloat(numPrice1).toFixed(2)
  return currency?.symbol + updatedPrcie
}

const Utils = {
  calculatePriceDifference: (price1, price2) => {
    // const numPrice1 = parseFloat(price1.replace("$", ""))
    // const numPrice2 = parseFloat(price2.replace("$", ""))

    // Remove the $ sign only if it exists
    const numPrice1 = parseFloat(
      price1.toString().includes("$") ? price1.replace("$", "") : price1
    )
    const numPrice2 = parseFloat(
      price2.toString().includes("$") ? price2.replace("$", "") : price2
    )

    const difference = numPrice1 - numPrice2
    return `$${difference.toFixed(2)}`
  },

  calculatePriceDifferenceForAuthUser: (price1, price2, symbol) => {
    //console.log("===============>", { price1, price2, symbol })
    const numPrice1 = parseFloat(
      price1.toString().includes(symbol ?? "$")
        ? price1.replace(symbol ?? "$", "")
        : price1
    )
    const numPrice2 = parseFloat(
      price2.toString().includes(symbol ?? "$")
        ? price2.replace(symbol ?? "$", "")
        : price2
    )
    const difference = numPrice1 - numPrice2
    return `${symbol}${difference.toFixed(2)}`
  },

  calculatePriceDifferenceQty: (price1, price2, qty) => {
    // const numPrice1 = parseFloat(price1.replace("$", ""))
    // const numPrice2 = parseFloat(price2.replace("$", ""))
    // Remove the $ sign only if it exists
    const numPrice1 = parseFloat(
      price1.toString().includes("$") ? price1.replace("$", "") : price1
    )
    const numPrice2 = parseFloat(
      price2.toString().includes("$") ? price2.replace("$", "") : price2
    )

    const difference = qty * (numPrice1 - numPrice2)
    return `$${difference.toFixed(2)}`
  },

  getTotalDiscountAmount: discounts => {
    return discounts
      .reduce((total, discount) => {
        // Remove the "$" and parse the discount amount to a number
        const amount = parseFloat(discount.discountAmount.replace("$", ""))
        return total + amount
      }, 0)
      ?.toFixed(2)
  },

  checkIfAnyFilterValueExists: filterObject => {
    const keysToIgnore = ["after", "category", "first", "tag"]

    return Object.keys(filterObject).some(key => {
      return !keysToIgnore.includes(key) && filterObject[key] !== ""
    })
  },

  getIfAnyFilterValueExists: filterObject => {
    const keysToIgnore = ["after", "category", "first", "tag"]
    const filterValues = []
    Object.keys(filterObject).map(key => {
      if (!keysToIgnore.includes(key) && filterObject[key] !== "") {
        filterValues.push(key)
      }
    })
    return filterValues
  },

  validateEmail: email => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return regex.test(email)
  },

  generateUniqueId: () => {
    // Get current date and time
    const now = new Date()

    // Format date and time components
    const date = now.toISOString().split("T")[0] // YYYY-MM-DD
    const time = now.toTimeString().split(" ")[0].replace(/:/g, "") // HHMMSS

    // Generate a random number for additional uniqueness
    const randomNum = Math.floor(Math.random() * 10000) // Random number between 0 and 9999

    // Combine all parts into a unique ID
    const uniqueId = `${date}-${time}-${randomNum}`

    return uniqueId
  },
}

export default Utils

export const autoLogin = async () => {
  const authContextStorageValue =
    typeof window !== "undefined"
      ? JSON.parse(localStorage.getItem("userDetails"))
      : ""

  if (!authContextStorageValue?.username) {
    console.error("Unable to auto login as username & password NOT found")
    return
  }
  const { username, password } = authContextStorageValue
  // Get the variables from user input
  const variables = {
    username,
    password,
  }

  // Define the GraphQL query
  const query = `
    mutation LoginUser($username: String!, $password: String!) {
      login(input :{username: $username, password: $password}) {
        authToken
        customer {
          databaseId,
          username,
          email
        }
        
      }
    }
    `

  try {
    const response = await axios.post(
      `${process.env.WP_API_URL}`,
      {
        query: query,
        variables: variables,
      },
      {
        headers: {
          "Content-Type": "application/json",
          // Authorization: `Bearer YOUR_AUTH_TOKEN`, // If authentication is needed
        },
      }
    )

    // Handle the response
    if (response.status === 200) {
      if (!response?.data?.data?.login) {
        console.error(
          "Unable to auto login. Please verify your username & password"
        )
        return
      }

      const { authToken } = response?.data?.data?.login
      const { username, email, databaseId } =
        response?.data?.data?.login?.customer

      return {
        authToken,
        username,
        email,
        databaseId,
      }
    }
  } catch (error) {
    console.error("Error fetching data:", error)
  }
}
